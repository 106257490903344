import React, { useCallback, useEffect, useState } from 'react';
import Menu from '../Menu/Menu';
import { useLocation, useNavigate } from 'react-router-dom';
import { API_URL } from '../../Config';
import axios from 'axios';

const EditBooking = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [villas, setVillas] = useState([]);
    const [isLoading, setIsLoading] = useState();
    const villaDetails = location.state?.villaDetails || '';

    const fetchRooms = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await fetch(API_URL + '/roomsController');
            const data = await response.json();
            setVillas(data);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching rooms:', error);
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchRooms();
    }, [fetchRooms]);
    const [formData, setFormData] = useState({
        id: villaDetails.id || '',
        villaId: villaDetails.villaId || '',
        villaName: villaDetails.villaName || '',
        name: villaDetails.name || '',
        email: villaDetails.email || '',
        phone: villaDetails.phone || '',
        adults: villaDetails.adults || '',
        children: villaDetails.children || '',
        property_type: villaDetails.property_type || '',
        specialRequests: villaDetails.specialRequests || '',
        checkIn: villaDetails.checkIn || '',
        checkOut: villaDetails.checkOut || '',
        price: villaDetails.price || 0,
        pendingPrice: villaDetails.pendingPrice || 0,
        taxes: villaDetails.taxes || 0,
        discount: villaDetails.discount || 0,
        total: villaDetails.total || '',
        payment_status: villaDetails.payment_status || '',
        booking_status: villaDetails.booking_status || '',
        booking_portal: villaDetails.booking_portal || '',

    });
    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === 'villaName') {
            const selectedVilla = villas.find(villa => villa.villaName === value);
            setFormData({
                ...formData,
                [name]: value,
                villaId: selectedVilla ? selectedVilla.id : ''
            });
        } else if (name === "checkIn") {
            const checkInDate = new Date(value);
            checkInDate.setDate(checkInDate.getDate() + 1);
            setFormData((prevData) => ({
                ...prevData,
                checkIn: value,
                checkOut: checkInDate.toISOString().split("T")[0]
            }));
        } else if (name === "phone") {
            const numericValue = value.replace(/[^0-9]/g, "");
        
            if (numericValue.length <= 10) {
                setFormData((prevData) => ({
                    ...prevData,
                    [name]: numericValue,
                }));
            }
        } else {
            setFormData({
                ...formData,
                [name]: value
            });
        }
    };

    const updateBooking = async () => {
        try {
            const response = await axios.put(API_URL + `/updateBookingDetails?id=${formData.id}`, formData);

            if (response.status === 200) {
                window.alert('Booking updated successfully!')
                navigate('/ManualBooking')
            } else {
                console.error('Error updating user');
            }
        } catch (error) {
            console.error(error);
        }

    };

    const calculateTotalAmount = useCallback(() => {
        const { price, pendingPrice, taxes, discount } = formData;
        const finalTotal = parseFloat(price) + parseFloat(pendingPrice) + parseFloat(taxes) - parseFloat(discount);
        return finalTotal;
    }, [formData]);

    useEffect(() => {
        const finalTotal = calculateTotalAmount();
        setFormData((prevFormData) => ({
            ...prevFormData,
            total: finalTotal.toFixed(), // To set total with 2 decimal places
        }));
    }, [formData, calculateTotalAmount]);


    return (
        <div className='text-[14px] md:text-xl lg:text-[16px] 2xl:text-2xl leading-6 md:leading-8 lg:leading-7 2xl:leading-10'>
            <Menu />
            {isLoading ? (
                <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
                    <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-white"></div>
                </div>
            ) : null}
            <div className='lg:ml-[8%] p-[3%] md:p-[2%]'>
                <p className='mb-[2%] font-semibold'>{villaDetails.villaName} Booking</p>

                <div className='grid grid-cols-1 lg:grid-cols-3 items-center'>
                    <div className='m-[2%] lg:mx-[5%]'>
                        <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Booking ID</p>
                        <input type="text" className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' name='id' value={formData.id} readOnly disabled />
                    </div>
                    <div className='m-[2%] lg:mx-[5%]'>

                        <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Villa/Apartment Name *</p>
                        <input type="text" className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' name='villaName' value={formData.villaName} readOnly disabled />
                    </div>
                    <div className='m-[2%] lg:mx-[5%]'>
                        <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'> Full Name</p>
                        <input type="text" className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' name='name' value={formData.name} onChange={handleInputChange} />
                    </div>

                    <div className='m-[2%] lg:mx-[5%]'>
                        <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Email</p>
                        <input type="text" className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' name='email' value={formData.email} onChange={handleInputChange} />
                    </div>

                    <div className='m-[2%] lg:mx-[5%]'>
                        <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Phone Number</p>
                        <input type="tel" name="phone" value={formData.phone} onChange={handleInputChange} className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' />
                    </div>
                    <div className='m-[2%] lg:mx-[5%]'>
                        <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Adults</p>
                        <input type="number" name="adults" value={formData.adults} min="1" onChange={handleInputChange} className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' />
                    </div>
                    <div className='m-[2%] lg:mx-[5%]'>
                        <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Children</p>
                        <input type="number" name="children" value={formData.children} onChange={handleInputChange} className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' />
                    </div>
                </div>

                <div className='grid grid-cols-1 lg:grid-cols-2 items-center'>
                    <div className='m-[2%] lg:mx-[3%] lg:w-[60%]'>
                        <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Property Type *</p>
                        <input type="text" className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' name='property_type' value={formData.property_type} readOnly disabled />
                    </div>
                    <div className='m-[2%] lg:mx-[3%] lg:-ml-[30%]'>
                        <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Special Requests</p>
                        <input type="text" name="specialRequests" value={formData.specialRequests} onChange={handleInputChange} className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' />
                    </div>
                </div>


                <div className='grid grid-cols-1 lg:grid-cols-3 items-center'>
                    <div className='m-[2%] lg:mx-[5%]'>
                        <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Check In</p>
                        <input 
                            type="date" className='bg-white border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' 
                            min={new Date().toISOString().split("T")[0]}
                            name='checkIn' value={formData.checkIn} onChange={handleInputChange}/>
                    </div>

                    <div className='m-[2%] lg:mx-[5%]'>
                        <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Check Out</p>
                        <input 
                            type="date" className='bg-white border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' 
                            min={formData.checkIn ? new Date(new Date(formData.checkIn).setDate(new Date(formData.checkIn).getDate() + 1)).toISOString().split("T")[0] : new Date().toISOString().split("T")[0]}
                            name='checkOut' value={formData.checkOut} onChange={handleInputChange} />
                    </div>

                    <div className='m-[2%] lg:mx-[5%]'>
                        <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Advances Amount</p>
                        <input type="text" className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' name='price' value={formData.price} onChange={handleInputChange} />
                    </div>

                    <div className='m-[2%] lg:mx-[5%]'>
                        <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Balance Amount</p>
                        <input type="text" className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' name='pendingPrice' value={formData.pendingPrice} onChange={handleInputChange} />
                    </div>

                    <div className='m-[2%] lg:mx-[5%]'>
                        <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Taxe Amount</p>
                        <input type="text" className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' name='taxes' value={formData.taxes} onChange={handleInputChange} />
                    </div>

                    <div className='m-[2%] lg:mx-[5%]'>
                        <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Discount Amount</p>
                        <input type="text" className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' name='discount' value={formData.discount} onChange={handleInputChange} />
                    </div>

                    <div className='m-[2%] lg:mx-[5%]'>
                        <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Total Amount</p>
                        <input type="text" className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' name='total' value={formData.total} onChange={handleInputChange} />
                    </div>

                    <div className='m-[2%] lg:mx-[5%]'>
                        <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Payment Status *</p>
                        {/* <input type="text" className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' name='payment_status' value={formData.payment_status} onChange={handleInputChange} /> */}
                        <select style={{ appearance: 'none' }} className='bg-white border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' name='payment_status' value={formData.payment_status} onChange={handleInputChange}>
                            <option value='' disabled hidden>Select</option>
                            <option value='PAYMENT_PENDING' >PENDING</option>
                            {/* <option value='PAYMENT_PARTIAL' >PARTIAL</option> */}
                            <option value="PAYMENT_SUCCESS">SUCCESS</option>
                        </select>
                    </div>

                    <div className='m-[2%] lg:mx-[5%]'>
                        <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Booking Status</p>
                        {/* <input type="text" className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' name='booking_status' value={formData.booking_status} onChange={handleInputChange} /> */}
                        <select style={{ appearance: 'none' }} className='bg-white border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' name='booking_status' value={formData.booking_status} onChange={handleInputChange}>
                            <option value='' disabled hidden>Select</option>
                            {/* <option value='PENDING' >PENDING</option> */}
                            <option value="SUCCESS">SUCCESS</option>
                            <option value="CANCEL">CANCEL</option>
                        </select>
                    </div>

                    <div className='m-[2%] lg:mx-[5%]'>
                        <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Booking Portal *</p>
                        {/* <input type="text" className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' name='booking_portal' value={formData.booking_portal} onChange={handleInputChange} /> */}
                        <select style={{ appearance: 'none' }} className='bg-white border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' name='booking_portal' value={formData.booking_portal} onChange={handleInputChange}>
                            <option value='' disabled hidden>Select</option>
                            <option value='OTA' className=''>OTA</option>
                            <option value='Agents' className=''>Agents</option>
                            <option value='Direct' className=''>Direct</option>
                            <option value='Website' className=''>Website</option>
                        </select>
                    </div>
                </div>

                <div className='flex my-[3%] lg:my-[1%] ml-[2%]'>
                    <button className="cancel_btn" onClick={() => navigate('/ManualBooking')}>Cancel</button>

                    <button className="done_btn" onClick={() => updateBooking()}>Done</button>
                </div>

            </div>
        </div>
    );
};

export default EditBooking;