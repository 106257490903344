import React, { useState } from 'react';
import Menu from '../Menu/Menu';
import { IoIosCloseCircle } from "react-icons/io";

const EditManualBooking = () => {

    const [selectedAddOns, setSelectedAddOns] = useState([]);
    const addOnsList = ['Yacht', 'Pickup & Drop', 'Car Rental/Self Drive', 'Event Decor', 'Shisha Service'];

    const handleAddOnClick = (addOn) => {
        setSelectedAddOns([...selectedAddOns, addOn]);
    };

    const handleRemoveOnClick = (indexToRemove) => {
        const updatedAddOns = selectedAddOns.filter((_, index) => index !== indexToRemove);
        setSelectedAddOns(updatedAddOns);
    };

    return (
        <div className='text-[14px] md:text-xl lg:text-[16px] 2xl:text-2xl leading-6 md:leading-8 lg:leading-7 2xl:leading-10'>
            <Menu />
            <div className='lg:ml-[8%] p-[3%] md:p-[2%]'>
                <p className='mb-[2%] font-semibold'>Edit Custom Booking</p>

                <div className='grid grid-cols-1 lg:grid-cols-3 items-center'>
                    <div className='m-[2%] lg:mx-[5%]'>
                        <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Full Name</p>
                        <input type="text" className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' />
                    </div>

                    <div className='m-[2%] lg:mx-[5%]'>
                        <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Email ID</p>
                        <input type="text" className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' />
                    </div>

                    <div className='m-[2%] lg:mx-[5%]'>
                        <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Phone Number</p>
                        <input type="text" className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' />
                    </div>
                </div>

                <div className='grid grid-cols-1 lg:grid-cols-2 items-center'>
                    <div className='m-[2%] lg:mx-[3%] lg:w-[60%]'>
                        <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Property Type</p>
                        <select className='bg-white border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' style={{ appearance: 'none' }}>
                            <option className=''  disabled hidden>Select</option>
                            <option className=''>Villa Arcaida</option>
                            <option className=''>Villa Serenity</option>
                            <option className=''>Villa Celebration</option>
                        </select>                    
                    </div>

                    <div className='m-[2%] lg:mx-[3%] lg:-ml-[30%]'>
                        <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Add-ons</p>
                        {/* <input type="text" className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md'/> */}

                        <div className={`flex flex-col lg:flex-row flex-wrap border-[2px] p-[1%] px-[2%] ${selectedAddOns.length === 0 ? 'h-[40px] 2xl:h-[55px]' : 'h-auto'} w-full rounded-lg`}>
                            {selectedAddOns.map((addOn, index) => (
                                <div key={index} className='flex justify-between items-center bg-[#f8ecf4] m-1 py-0 px-[5px] text-xs rounded'>
                                    &nbsp;{addOn}&nbsp;
                                    <button
                                        className='bg-[#f8ecf4] py-[6px] m-1 px-[10px] text-xs rounded'
                                        onClick={() => handleRemoveOnClick(index)}
                                    >
                                        <IoIosCloseCircle style={{ color: '#696969' }} />
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className='mx-[2%] lg:ml-[35%] lg:mb-[0.5%]'>
                    {addOnsList.filter(addOn => !selectedAddOns.includes(addOn)).map((addOn, index) => (
                        <button
                            key={index}
                            className='bg-[#f8ecf4] m-1 py-[6px] px-[10px] text-xs rounded'
                            onClick={() => handleAddOnClick(addOn)}
                        >
                            {addOn}
                        </button>
                    ))}
                </div>

                <div className='grid grid-cols-1 lg:grid-cols-3 items-center'>
                    <div className='m-[2%] lg:mx-[5%]'>
                        <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Check In</p>
                        <input type="date" className='bg-white border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' />
                    </div>

                    <div className='m-[2%] lg:mx-[5%]'>
                        <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Check Out</p>
                        <input type="date" className='bg-white border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' />
                    </div>

                    <div className='m-[2%] lg:mx-[5%]'>
                        <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Amount</p>
                        <input type="text" className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' />
                    </div>

                    <div className='m-[2%] lg:mx-[5%]'>
                        <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Taxes</p>
                        <input type="text" className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' />
                    </div>

                    <div className='m-[2%] lg:mx-[5%]'>
                        <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Discount (Enter nil if not appropriate)</p>
                        <input type="text" className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' />
                    </div>

                    <div className='m-[2%] lg:mx-[5%]'>
                        <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Total Amount</p>
                        <input type="text" className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' />
                    </div>

                    <div className='m-[2%] lg:mx-[5%]'>
                        <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Payment Status</p>
                        <select style={{ appearance: 'none' }} className='bg-white border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md'>
                            <option className='' disabled hidden>Select</option>
                            <option value='PAYMENT_PENDING' >PENDING</option>
                            <option value='PAYMENT_PARTIAL' >PARTIAL</option>
                            <option value="PAYMENT_SUCCESS">SUCCESS</option>
                        </select>                                        
                    </div>

                    <div className='m-[2%] lg:mx-[5%]'>
                        <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Booking Status</p>
                        <select style={{ appearance: 'none' }} className='bg-white border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md'>
                            <option className='' disabled hidden>Select</option>
                            <option value='PENDING' >PENDING</option>
                            <option value="SUCCESS">SUCCESS</option>
                            <option value="CANCEL">CANCEL</option>
                        </select>                                        
                    </div>

                    <div className='m-[2%] lg:mx-[5%]'>
                        <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Booking Portal</p>
                        <select style={{ appearance: 'none' }} className='bg-white border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md'>
                            <option className='' disabled hidden>Select</option>
                            <option value='OTA' className=''>OTA</option>
                            <option value='Agents' className=''>Agents</option>
                            <option value='Direct' className=''>Direct</option>
                            <option value='Website' className=''>Website</option>
                        </select>                                        
                    </div>
                </div>

                <div className='flex my-[3%] lg:my-[1%] ml-[2%]'>
                    <button className="cancel_btn">Cancel</button>

                    <button className="done_btn">Done</button>
                </div>

            </div>
        </div>
    );
};

export default EditManualBooking;