import React, { useCallback, useEffect, useState } from "react";
import Menu from "../Menu/Menu";
import { API_URL } from "../../Config";
import { useNavigate } from "react-router-dom";

const months = {
  Jan: 31,
  Feb: 28,
  Mar: 31,
  Apr: 30,
  May: 31,
  Jun: 30,
  Jul: 31,
  Aug: 31,
  Sep: 30,
  Oct: 31,
  Nov: 30,
  Dec: 31,
};

const generateDates = (days) => {
  return Array.from({ length: days }, (_, i) => i + 1);
};

const isLeapYear = (year) => {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
};

const MyCalendar = () => {
  const currentMonth = new Date().toLocaleString("default", { month: "short" });
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  if (isLeapYear(selectedYear)) {
    months["Feb"] = 29;
  } else {
    months["Feb"] = 28;
  }

  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
  };

  const handleYearChange = (e) => {
    setSelectedYear(Number(e.target.value));
  };

  const years = Array.from(
    { length: 10 },
    (_, i) => new Date().getFullYear() - 5 + i
  ); // Adjust range as needed

  const [availability, setAvailability] = useState([]);
  const [villass, setVillas] = useState([]);
  const [isLoading, setIsLoading] = useState();

  const fetchRooms = useCallback(async () => {
    try {
      const response = await fetch(API_URL + "/roomsController");
      const data = await response.json();
      const sattvaVilla = data.find((villa) => villa.id === 15);
      const otherVillas = data.filter((villa) => villa.id !== 15);

      let updatedVillas;
      if (sattvaVilla) {
        updatedVillas = [
          ...otherVillas.slice(0, 5),
          sattvaVilla,
          ...otherVillas.slice(5),
        ];
      } else {
        updatedVillas = otherVillas;
      }

      setVillas(updatedVillas);
    } catch (error) {
      console.error("Error fetching rooms:", error);
    }
  }, []);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const checkAvailability = useCallback(async (month, year) => {
    setIsLoading(true);

    const daysInMonth = months[month];
    const availabilityData = [];

    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(year, Object.keys(months).indexOf(month), day);
      const formattedDate = formatDate(date);

      const response = await fetch(
        API_URL +
          `/bookingAvailabilityController?startDate=${formattedDate}&endDate=${formattedDate}`
      );
      const data = await response.json();
      availabilityData.push({
        date: formattedDate,
        availability: data.availability,
      });
    }

    setAvailability(availabilityData);
    setIsLoading(false);
  }, []);

  const getVillaAvailability = (villaId, date) => {
    const dayAvailability = availability.find((avail) => avail.date === date);
    if (!dayAvailability) return 0;
    const villa = dayAvailability.availability.find(
      (avail) => avail.villaId === villaId
    );
    return villa ? villa : 0;
  };

  useEffect(() => {
    fetchRooms();
    checkAvailability(selectedMonth, selectedYear);
  }, [fetchRooms, checkAvailability, selectedMonth, selectedYear]);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const isPastDate = (day, month, year) => {
    const selectedDate = new Date(
      year,
      Object.keys(months).indexOf(month),
      day
    );
    selectedDate.setHours(0, 0, 0, 0);

    return selectedDate < today;
  };

  const navigate = useNavigate();

  const handle_AddManualBooking = (villaName, property_type) => {
    navigate("/AddManualBooking", { state: { villaName, property_type } });
  };

  return (
    <div className="text-[14px] md:text-xl lg:text-[16px] 2xl:text-2xl leading-6 md:leading-8 lg:leading-7 2xl:leading-10">
      <Menu />
      <div className="lg:ml-[8%] p-[3%] md:p-[2%]">
        <p className="mb-[2%] lg:ml-[1%] font-semibold">Calendar</p>

        <div className="grid grid-cols-1 lg:grid-cols-3 items-center">
          {isLoading ? (
            <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
              <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-white"></div>
            </div>
          ) : null}

          {/* <div className='m-[2%] lg:mx-[5%]'>
            <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Property Type</p>
            <select className='bg-white border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md'>
              <option value='' disabled hidden>Select</option>
              <option value='' >option_1</option>
              <option value="">option_2</option>
              <option value="">option_3</option>
            </select>
          </div>

          <div className='m-[2%] lg:mx-[5%]'>
            <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Properties Selection</p>
            <select className='bg-white border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md'>
              <option value='' disabled hidden>Select</option>
              <option value='' >option_1</option>
              <option value='' >option_2</option>
              <option value='' >option_3</option>
            </select>
          </div>


          <div className='m-[2%] lg:mx-[5%]'>
            <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Booking Status</p>
            <select className='bg-white border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md'>
              <option value='' disabled hidden>Select</option>
              <option value='' >Booked</option>
              <option value='' >Pending</option>
              <option value="">Available</option>
            </select>
          </div>

          <div className='m-[2%] lg:mx-[5%]'>
            <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Check-In</p>
            <input type="date" className='bg-white border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' />
          </div>

          <div className='m-[2%] lg:mx-[5%]'>
            <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Check-Out</p>
            <input type="date" className='bg-white border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' />
          </div> */}
        </div>

        {/* <div className='flex my-[1%] justify-end mr-[1.5%]'>
          <button className="done_btn">Done</button>
        </div> */}

        <div className="grid grid-cols-2 lg:grid-cols-4 ml-[1%] my-[5%] lg:mt-0 lg:mb-[2%] ">
          <div className="flex items-center gap-1">
            <div className="h-[1em] w-[1em] rounded border border-black bg-[#7a7162]"></div>
            Confirmed Booking
          </div>

          {/* <div className='flex items-center gap-1'>
            <div className='h-[1em] w-[1em] rounded border border-black bg-[#C6BFB0]'></div>Partial Payment
          </div> */}

          <div className="flex items-center gap-1">
            <div className="h-[1em] w-[1em] rounded border border-black bg-[#FFFFFF]"></div>
            Available
          </div>

          <div className="flex items-center gap-1">
            <div className="h-[1em] w-[1em] rounded border border-black bg-[#C0C0C0]"></div>
            Waiting For payment
          </div>
        </div>

        <div className="mx-[2%] md:mx-[1%] overflow-x-auto">
          <select
            value={selectedMonth}
            onChange={handleMonthChange}
            className="bg-white border-[1px] outline-none border-[#444444] rounded-lg px-2 py-1 mb-[2%]"
          >
            {Object.keys(months).map((month) => (
              <option key={month} value={month}>
                {month}
              </option>
            ))}
          </select>

          <select
            value={selectedYear}
            onChange={handleYearChange}
            className="bg-white border-[1px] outline-none border-[#444444] rounded-lg px-2 py-1 mb-[2%] ml-2"
          >
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>

          <table
            style={{ width: "100%" }}
            className="text-[14px] 2xl:text-[18px]"
          >
            <thead>
              <tr>
                <th
                  rowSpan="4"
                  style={{
                    backgroundColor: "#E7ECEB",
                    border: "solid 1px",
                    borderLeft: "solid 2px",
                    borderRight: "solid 2px",
                    padding: "5px",
                    textAlign: "center",
                  }}
                >
                  {selectedMonth}
                </th>
                <th
                  colSpan="6"
                  style={{
                    backgroundColor: "#D9D9D9",
                    border: "solid 1px",
                    borderRight: "solid 2px",
                    padding: "5px",
                    textAlign: "center",
                  }}
                >
                  VILLAS
                </th>
                <th
                  colSpan="4"
                  style={{
                    backgroundColor: "#D9D9D9",
                    border: "solid 1px",
                    borderRight: "solid 2px",
                    padding: "5px",
                    textAlign: "center",
                  }}
                >
                  ASHVILLE HOMES
                </th>
                <th
                  colSpan="2"
                  style={{
                    backgroundColor: "#D9D9D9",
                    border: "solid 1px",
                    borderRight: "solid 1px",
                    padding: "5px",
                    textAlign: "center",
                  }}
                >
                  APARTMENTS
                </th>
              </tr>
              <tr>
                {villass.map((villa, index) => (
                  <th
                    key={index}
                    style={{
                      backgroundColor: "#E7ECEB",
                      border: "solid 1px",
                      padding: "5px",
                      textAlign: "center",
                      fontWeight: "500",
                      borderRight:
                        index === 5 || index === 9 ? "solid 2px" : "solid 1px",
                    }}
                  >
                    {villa.villaName}
                  </th>
                ))}
              </tr>
              <tr>
                {villass.map((_, index) => (
                  <th
                    key={index}
                    style={{
                      backgroundColor: "#E7ECEB",
                      border: "solid 1px",
                      padding: "5px",
                      textAlign: "center",
                      fontWeight: "500",
                      borderRight:
                        index === 5 || index === 9 ? "solid 2px" : "solid 1px",
                    }}
                  >{`${_.max_bedrooms}BHK`}</th>
                ))}
              </tr>
            </thead>
            <tbody className="font-light">
              {generateDates(months[selectedMonth]).map((date, rowIndex) => (
                <tr key={rowIndex}>
                  <td
                    style={{
                      border: "solid 1px",
                      borderLeft: "solid 2px",
                      borderRight: "solid 2px",
                      padding: "5px",
                      textAlign: "center",
                    }}
                  >{`${selectedMonth} - ${date}`}</td>
                  {villass.map((villa, colIndex) => {
                    const availability = getVillaAvailability(
                      villa.id,
                      `${selectedYear}-${String(
                        Object.keys(months).indexOf(selectedMonth) + 1
                      ).padStart(2, "0")}-${String(date).padStart(2, "0")}`
                    );
                    const cellStyle = {
                      border: "solid 1px",
                      padding: "5px",
                      textAlign: "center",
                      borderRight:
                        colIndex === 5 || colIndex === 9
                          ? "solid 2px"
                          : "solid 1px",
                      backgroundColor:
                        availability.available === 0 &&
                        availability.paymentStatuses === "PAYMENT_SUCCESS"
                          ? "#7a7162"
                          : availability.available === 0 &&
                            availability.paymentStatuses === "PAYMENT_PENDING"
                          ? "#C0C0C0"
                          : "#FFFFFF",
                    };
                    return (
                      <td key={colIndex} style={cellStyle}>
                        {isPastDate(date, selectedMonth, selectedYear) ? (
                          <span className="text-black">Not Available</span>
                        ) : availability.available !== 0 ? (
                          <button
                            onClick={() =>
                              handle_AddManualBooking(villa.villaName)
                            }
                            className="text-black"
                          >
                            Book Now
                          </button>
                        ) : (
                          <span className="text-black">Not Available</span>
                        )}
                      </td>

                      // <td key={colIndex} style={cellStyle}>
                      //   {availability.available !== 0 ? (
                      //     <Link to={`/AddManualBooking`} className="text-black">Book Now</Link>
                      //   ) : (
                      //     <span className="text-black">Not Available</span>
                      //   )}
                      // </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <br />
      </div>
    </div>
  );
};

export default MyCalendar;
