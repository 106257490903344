import React, { useCallback, useEffect, useState } from "react";
import "../Dashboard/Dashboard.css";
import Menu from "../Menu/Menu";
import { HiOutlineInformationCircle } from "react-icons/hi2";
import { MdOutlineDeleteForever } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../Config";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const UserDatabase = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isHandle_Delete, setHandle_Delete] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState(null);

  const handleAddUser = () => {
    navigate("/AddUser");
  };

  const openForm_Delete = (userId) => {
    setDeleteUserId(userId);
    setHandle_Delete(true);
  };

  const closeForm_Delete = () => {
    setHandle_Delete(false);
    setDeleteUserId(null);
  };

  const fetchUsers = useCallback(async () => {
    try {
      const response = await fetch(API_URL + "/userDetails", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      let data = await response.json();
      setUsers(data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const deleteUser = async () => {
    try {
      const response = await fetch(
        `${API_URL}/deleteUser?email=${deleteUserId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        setUsers(users.filter((user) => user.email !== deleteUserId));
        closeForm_Delete();
      } else {
        console.error("Failed to delete user");
      }
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const filteredUsers = users.filter((i) => {
    const searchQueryLower = searchQuery.toLowerCase();
    i.firstName = i.firstName ? i.firstName.toString() : "";
    i.lastName = i.lastName ? i.lastName.toString() : "";
    i.email = i.email ? i.email.toString() : "";
    i.phone = i.phone ? i.phone.toString() : "";
    i.country = i.country ? i.country.toString() : "";
    i.city = i.city ? i.city.toString() : "";

    return (
      searchQuery === "" ||
      i.firstName.toLowerCase().includes(searchQueryLower) ||
      i.lastName.toString().includes(searchQueryLower) ||
      i.email.toLowerCase().includes(searchQueryLower) ||
      i.phone.toString().includes(searchQueryLower) ||
      i.country.toLowerCase().includes(searchQueryLower) ||
      i.city.toLowerCase().includes(searchQueryLower)
    );
  });

  const tableTitles = [
    "Sr.No.",
    "First Name",
    "Last Name",
    "Email ID",
    "Phone No.",
    "Country",
    "City",
    "Password",
    "Action",
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows =
    filteredUsers.length > 0
      ? filteredUsers.slice(indexOfFirstRow, indexOfLastRow)
      : [];

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const Pagination = ({ rowsPerPage, totalRows, paginate, currentPage }) => {
    const totalPages = Math.ceil(totalRows / rowsPerPage);

    return (
      <nav className="flex justify-end my-[2%]">
        <ul className="flex items-center">
          {currentPage > 1 && (
            <li className="mx-1">
              <button
                onClick={() => paginate(currentPage - 1)}
                className="p-1 bg-gray-200 text-xs rounded-full"
              >
                <MdKeyboardArrowLeft />
              </button>
            </li>
          )}
          <li className="mx-1">
            <span className="px-2 py-2 text-xs">
              {currentPage} / {totalPages}
            </span>
          </li>
          {currentPage < totalPages && (
            <li className="mx-1">
              <button
                onClick={() => paginate(currentPage + 1)}
                className="p-1 bg-gray-200 text-xs rounded-full"
              >
                <MdKeyboardArrowRight />
              </button>
            </li>
          )}
        </ul>
      </nav>
    );
  };

  const handleEditUser = (row) => {
    navigate("/EditUser", { state: { userDetails: row } });
  };

  const [fileName, setFileName] = useState("Total Revenue");
  const handleExport = () => {
    const userInput = window.prompt("Enter file name:", "Total Revenue");
    if (userInput) {
      setFileName(userInput.trim());
      handleExportConfirm();
    }
  };

  const handleExportConfirm = () => {
    const exportData = currentRows;

    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    const wbout = XLSX.write(wb, { type: "binary", bookType: "xlsx" });

    const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });

    saveAs(blob, `${fileName}.xlsx`);
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };

  return (
    <div className="text-[14px] md:text-xl lg:text-[16px] 2xl:text-2xl leading-6 md:leading-8 lg:leading-7 2xl:leading-10">
      <Menu />
      <div className="lg:ml-[8%] p-[3%] md:p-[2%]">
        <div className="flex flex-col lg:flex-row justify-between lg:items-center mx-[3%] md:mx-[1%] mb-[2%]">
          <div className="flex items-center justify-between">
            <p className="font-semibold">User Management</p>

            {/* <button className='lg:hidden flex items-center ml-2 h-[35px] border-[1px] border-[#444444] rounded-lg px-2 text-[#444444] text-[12px] bg-white hover:bg-[#444444] hover:text-white' onClick={handleAddUser}>
                            Add&nbsp;User
                        </button> */}
          </div>

          <div className="flex my-[2%] lg:my-0">
            <div className="p-2 px-5 overflow-hidden w-[50px] h-[35px] hover:w-[250px] bg-white shadow-sm rounded-lg flex group items-center hover:duration-300 duration-300 cursor-pointer">
              <div className="flex items-center justify-center fill-[#444444]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="Isolation_Mode"
                  data-name="Isolation Mode"
                  viewBox="0 0 24 24"
                  width="12"
                  height="12"
                >
                  <path d="M18.9,16.776A10.539,10.539,0,1,0,16.776,18.9l5.1,5.1L24,21.88ZM10.5,18A7.5,7.5,0,1,1,18,10.5,7.507,7.507,0,0,1,10.5,18Z"></path>
                </svg>
              </div>
              <input
                type="text"
                className="outline-none text-xs bg-transparent w-full text-gray-500 font-normal px-4"
                placeholder="Search here..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>

            <button
              className="download_btn ml-2"
              onClick={() => handleExport()}
            >
              <svg
                className="svgIcon"
                viewBox="0 0 384 512"
                height="1rem"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"></path>
              </svg>
              <span className="icon2"></span>
              <span className="tooltip">Download</span>
            </button>
            {/* <div className=''> */}
            <button
              className="flex items-center ml-2 h-[35px] border-[1px] border-[#444444] rounded-lg px-2 text-[#444444] text-[12px] bg-white hover:bg-[#877D6F] hover:text-white"
              onClick={handleAddUser}
            >
              Add&nbsp;User
            </button>
            {/* </div> */}
          </div>
        </div>

        <div className="mx-[2%] md:mx-[1%] overflow-x-auto">
          <table className="w-full border-collapse text-[#7C7C7C] text-[14px] 2xl:text-[18px]">
            <thead className="bg-[#E7ECEB]">
              <tr>
                {tableTitles.map((tableTitle, index) => (
                  <th key={index} className="border-b p-2 text-left">
                    {tableTitle}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {currentRows.map((row, index) => (
                <tr
                  key={index}
                  className={`${index % 2 === 0 ? "bg-white" : "bg-[#F7F7F7]"}`}
                >
                  <td className="p-2">{row.id}</td>
                  <td className="p-2">{row.firstName}</td>
                  <td className="p-2">{row.lastName}</td>
                  <td className="p-2">{row.email}</td>
                  <td className="p-2">{row.phone}</td>
                  <td className="p-2">{row.country}</td>
                  <td className="p-2">{row.city}</td>
                  <td className="p-2">{row.password}</td>
                  <td className="p-2 flex justify-start">
                    <button
                      onClick={() => handleEditUser(row)}
                      className="text-[18px] hover:scale-110"
                    >
                      <HiOutlineInformationCircle />
                    </button>
                    <button
                      className="text-[19px] ml-[20%] hover:scale-110 hover:text-red-700"
                      onClick={() => openForm_Delete(row.email)}
                    >
                      <MdOutlineDeleteForever />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Pagination
          rowsPerPage={rowsPerPage}
          totalRows={users.length}
          paginate={paginate}
          currentPage={currentPage}
        />

        {isHandle_Delete && (
          <>
            <div className="overlay_Delete" onClick={closeForm_Delete}></div>
            <div className="popup_form_Delete">
              <span className="close_Delete" onClick={closeForm_Delete}>
                &times;
              </span>
              <div>
                <p className="text-white mb-[5%]">
                  Are you sure you want to delete this User?
                </p>
                <button
                  className="bg-white text-[#877D6F] rounded-lg p-[1%] w-[20%] hover:scale-90 mr-[2%]"
                  onClick={closeForm_Delete}
                >
                  Cancel
                </button>
                <button
                  className="bg-[#877D6F] text-white rounded-lg p-[1%] w-[20%] hover:scale-90"
                  onClick={deleteUser}
                >
                  Yes
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default UserDatabase;
