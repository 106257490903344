import React, { useState } from 'react';
import axios from 'axios';
import './PricingCalendar.css';
import { MdOutlineKeyboardDoubleArrowLeft } from "react-icons/md";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { LiaRupeeSignSolid } from "react-icons/lia";

const PricingCalendar = ({ villaId }) => {
  const [selectedDates, setSelectedDates] = useState({ start: '', end: '' });
  const [price, setPrice] = useState('');
  const [defaultPrice, setDefaultPrice] = useState('');
  const [prices, setPrices] = useState({});
  const [currentDate, setCurrentDate] = useState(new Date());

  const handleDayClick = (dateString) => {
    const { start, end } = selectedDates;

    if (!start || (start && end)) {
      setSelectedDates({ start: dateString, end: '' });
    } else if (new Date(dateString) >= new Date(start)) {
      setSelectedDates({ ...selectedDates, end: dateString });
    } else {
      setSelectedDates({ start: dateString, end: start });
    }
  };

  const handleSetPrice = async () => {
    const { start, end } = selectedDates;

    if (price && start) {
      const newPrices = { ...prices };
      const startDate = new Date(start);
      const endDate = end ? new Date(end) : startDate;

      endDate.setHours(23, 59, 59, 999);

      const priceData = [];
      for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
        const dateString = d.toISOString().split('T')[0];
        newPrices[dateString] = parseInt(price);
        priceData.push({ date: dateString, price: parseInt(price) });
      }

      try {
        await axios.post(`/api/villas/${villaId}/update-price`, {
          villaId,
          priceData,
        });

        setPrices(newPrices);
        setSelectedDates({ start: '', end: '' });
        setPrice('');
      } catch (error) {
        console.error('Error updating price:', error);
      }
    }
  };

  const handleSetDefaultPrice = async () => {
    if (defaultPrice) {
      try {
        await axios.post(`/api/villas/${villaId}/update-default-price`, {
          villaId,
          defaultPrice: parseInt(defaultPrice),
        });
        setDefaultPrice(defaultPrice);
      } catch (error) {
        console.error('Error setting default price:', error);
      }
    }
  };

  const changeMonth = (increment) => {
    setCurrentDate(prevDate => {
      const newDate = new Date(prevDate);
      newDate.setMonth(newDate.getMonth() + increment);
      return newDate;
    });
  };

  const changeYear = (increment) => {
    setCurrentDate(prevDate => {
      const newDate = new Date(prevDate);
      newDate.setFullYear(newDate.getFullYear() + increment);
      return newDate;
    });
  };

  const renderCalendar = () => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);
    const daysInMonth = lastDay.getDate();
    const startingDayOfWeek = firstDay.getDay();

    const { start, end } = selectedDates;
    const calendarDays = [];

    for (let i = 0; i < startingDayOfWeek; i++) {
      calendarDays.push(<div key={`empty-${i}`} className="calendarDay_pricingCalendar"></div>);
    }

    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(year, month, day);
      const dateString = date.toISOString().split('T')[0];
      const isSelected = start && end ? dateString >= start && dateString <= end : dateString === start;

      calendarDays.push(
        <div
          key={dateString}
          className={`calendarDay_pricingCalendar ${isSelected ? 'selectedDay_pricingCalendar' : ''}`}
          onClick={() => handleDayClick(dateString)}
        >
          <div>{day}</div>
          <div className="price_pricingCalendar flex items-center justify-center"><LiaRupeeSignSolid />{prices[dateString] || defaultPrice || 'Not set'}</div>
        </div>
      );
    }

    return calendarDays;
  };

  return (
    <div className="container_pricingCalendar">
      <div className="inputGroup_pricingCalendar">
        <input
          type="number"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          placeholder="Enter price for selected dates"
          className="input_pricingCalendar"
        />
        <button onClick={handleSetPrice} className="button_pricingCalendar setButton_pricingCalendar">
          Set Price for Selected Dates
        </button>
      </div>
      <div className="inputGroup_pricingCalendar">
        <input
          type="number"
          value={defaultPrice}
          onChange={(e) => setDefaultPrice(e.target.value)}
          placeholder="Enter default price"
          className="input_pricingCalendar"
        />
        <button onClick={handleSetDefaultPrice} className="button_pricingCalendar setButton_pricingCalendar">
          Set Default Price
        </button>
      </div>
      <div className="calendarHeader_pricingCalendar">
        <button onClick={() => changeYear(-1)} className="button_pricingCalendar navButton_pricingCalendar flex items-center">
        <MdOutlineKeyboardDoubleArrowLeft /> Year
        </button>
        <button onClick={() => changeMonth(-1)} className="button_pricingCalendar navButton_pricingCalendar flex items-center">
        <MdOutlineKeyboardArrowLeft /> Month
        </button>
        <div className="currentDate_pricingCalendar">
          {currentDate.toLocaleString('default', { month: 'long', year: 'numeric' })}
        </div>
        <button onClick={() => changeMonth(1)} className="button_pricingCalendar navButton_pricingCalendar flex items-center">
        Month<MdOutlineKeyboardArrowRight /> 
        </button>
        <button onClick={() => changeYear(1)} className="button_pricingCalendar navButton_pricingCalendar flex items-center">
        Year<MdOutlineKeyboardDoubleArrowRight /> 
        </button>
      </div>
      <div className="calendar_pricingCalendar">
        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
          <div key={day} className="dayOfWeek_pricingCalendar">{day}</div>
        ))}
        {renderCalendar()}
      </div>
    </div>
  );
};

export default PricingCalendar;
