import React, { useCallback, useEffect, useState } from "react";
import "../Dashboard/Dashboard.css";
import Menu from "../Menu/Menu";
import { HiOutlineInformationCircle } from "react-icons/hi2";
import { MdOutlineDeleteForever } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { API_URL } from "../../Config";

const GenerateCoupon = () => {
  const navigate = useNavigate();
  const [coupons, setCoupons] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [deleteCouponId, setDeleteCouponId] = useState(null);

  const handleAddCoupon = () => {
    navigate("/AddCoupon");
  };

  const [isHandle_Delete, setHandle_Delete] = useState(false);

  const openForm_Delete = (userId) => {
    setDeleteCouponId(userId);
    setHandle_Delete(true);
  };

  const closeForm_Delete = () => {
    setHandle_Delete(false);
    setDeleteCouponId(null);
  };

  const fetchCoupons = useCallback(async () => {
    try {
      const response = await fetch(API_URL + "/couponsDetails", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      let data = await response.json();
      setCoupons(data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  }, []);

  useEffect(() => {
    fetchCoupons();
  }, [fetchCoupons]);

  const deleteCoupon = async () => {
    try {
      const response = await fetch(
        `${API_URL}/deleteCoupon?id=${deleteCouponId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        setCoupons(coupons.filter((coupon) => coupon.id !== deleteCouponId));
        closeForm_Delete();
      } else {
        console.error("Failed to delete user");
      }
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const tableTitles = [
    "Sr.No.",
    "Code",
    "Discount (in %)",
    "Expiry Date",
    "Action",
  ];

  const tableRows = [
    [
      "1",
      "abc",
      "15%",
      "20/02/2002",
      <div className="flex items-center">
        <Link to="/EditProperty" className="text-[18px] hover:scale-110">
          <HiOutlineInformationCircle />
        </Link>
        <button
          className="text-[19px] ml-[20%] hover:scale-110 hover:text-red-700"
          onClick={openForm_Delete}
        >
          <MdOutlineDeleteForever />
        </button>
      </div>,
    ],
    [
      "2",
      "cde",
      "10%",
      "20/02/2002",
      <div className="flex items-center">
        <Link to="/EditProperty" className="text-[18px] hover:scale-110">
          <HiOutlineInformationCircle />
        </Link>
        <button
          className="text-[19px] ml-[20%] hover:scale-110 hover:text-red-700"
          onClick={openForm_Delete}
        >
          <MdOutlineDeleteForever />
        </button>
      </div>,
    ],
    [
      "3",
      "abc",
      "15%",
      "20/02/2002",
      <div className="flex items-center">
        <Link to="/EditProperty" className="text-[18px] hover:scale-110">
          <HiOutlineInformationCircle />
        </Link>
        <button
          className="text-[19px] ml-[20%] hover:scale-110 hover:text-red-700"
          onClick={openForm_Delete}
        >
          <MdOutlineDeleteForever />
        </button>
      </div>,
    ],
    [
      "4",
      "cde",
      "10%",
      "20/02/2002",
      <div className="flex items-center">
        <Link to="/EditProperty" className="text-[18px] hover:scale-110">
          <HiOutlineInformationCircle />
        </Link>
        <button
          className="text-[19px] ml-[20%] hover:scale-110 hover:text-red-700"
          onClick={openForm_Delete}
        >
          <MdOutlineDeleteForever />
        </button>
      </div>,
    ],
    [
      "5",
      "abc",
      "15%",
      "20/02/2002",
      <div className="flex items-center">
        <Link to="/EditProperty" className="text-[18px] hover:scale-110">
          <HiOutlineInformationCircle />
        </Link>
        <button
          className="text-[19px] ml-[20%] hover:scale-110 hover:text-red-700"
          onClick={openForm_Delete}
        >
          <MdOutlineDeleteForever />
        </button>
      </div>,
    ],
    [
      "6",
      "abc",
      "10%",
      "20/02/2002",
      <div className="flex items-center">
        <Link to="/EditProperty" className="text-[18px] hover:scale-110">
          <HiOutlineInformationCircle />
        </Link>
        <button
          className="text-[19px] ml-[20%] hover:scale-110 hover:text-red-700"
          onClick={openForm_Delete}
        >
          <MdOutlineDeleteForever />
        </button>
      </div>,
    ],
    [
      "7",
      "cde",
      "15%",
      "20/02/2002",
      <div className="flex items-center">
        <Link to="/EditProperty" className="text-[18px] hover:scale-110">
          <HiOutlineInformationCircle />
        </Link>
        <button
          className="text-[19px] ml-[20%] hover:scale-110 hover:text-red-700"
          onClick={openForm_Delete}
        >
          <MdOutlineDeleteForever />
        </button>
      </div>,
    ],
    [
      "8",
      "abc",
      "10%",
      "20/02/2002",
      <div className="flex items-center">
        <Link to="/EditProperty" className="text-[18px] hover:scale-110">
          <HiOutlineInformationCircle />
        </Link>
        <button
          className="text-[19px] ml-[20%] hover:scale-110 hover:text-red-700"
          onClick={openForm_Delete}
        >
          <MdOutlineDeleteForever />
        </button>
      </div>,
    ],
    [
      "9",
      "cde",
      "15%",
      "20/02/2002",
      <div className="flex items-center">
        <Link to="/EditProperty" className="text-[18px] hover:scale-110">
          <HiOutlineInformationCircle />
        </Link>
        <button
          className="text-[19px] ml-[20%] hover:scale-110 hover:text-red-700"
          onClick={openForm_Delete}
        >
          <MdOutlineDeleteForever />
        </button>
      </div>,
    ],
    [
      "10",
      "abc",
      "10%",
      "20/02/2002",
      <div className="flex items-center">
        <Link to="/EditProperty" className="text-[18px] hover:scale-110">
          <HiOutlineInformationCircle />
        </Link>
        <button
          className="text-[19px] ml-[20%] hover:scale-110 hover:text-red-700"
          onClick={openForm_Delete}
        >
          <MdOutlineDeleteForever />
        </button>
      </div>,
    ],
  ];

  const filteredCoupons = coupons.filter((i) => {
    const searchQueryLower = searchQuery.toLowerCase();
    i.coupon_name = i.coupon_name ? i.coupon_name.toString() : "";
    i.discount_percentage = i.discount_percentage
      ? i.discount_percentage.toString()
      : "";

    return (
      searchQuery === "" ||
      i.coupon_name.toLowerCase().includes(searchQueryLower) ||
      i.discount_percentage.toString().includes(searchQueryLower)
    );
  });

  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 5;

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows =
    filteredCoupons.length > 0
      ? filteredCoupons.slice(indexOfFirstRow, indexOfLastRow)
      : [];

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const Pagination = ({ rowsPerPage, totalRows, paginate, currentPage }) => {
    const totalPages = Math.ceil(totalRows / rowsPerPage);

    return (
      <nav className="flex justify-end my-[2%]">
        <ul className="flex items-center">
          {currentPage > 1 && (
            <li className="mx-1">
              <button
                onClick={() => paginate(currentPage - 1)}
                className="p-1 bg-gray-200 text-xs rounded-full"
              >
                <MdKeyboardArrowLeft />
              </button>
            </li>
          )}
          <li className="mx-1">
            <span className="px-2 py-2 text-xs">
              {currentPage} / {totalPages}
            </span>
          </li>
          {currentPage < totalPages && (
            <li className="mx-1">
              <button
                onClick={() => paginate(currentPage + 1)}
                className="p-1 bg-gray-200 text-xs rounded-full"
              >
                <MdKeyboardArrowRight />
              </button>
            </li>
          )}
        </ul>
      </nav>
    );
  };
  const handleEditCoupon = (row) => {
    navigate("/EditCoupon", { state: { couponDetails: row } });
  };

  return (
    <div className="text-[14px] md:text-xl lg:text-[16px] 2xl:text-2xl leading-6 md:leading-8 lg:leading-7 2xl:leading-10">
      <Menu />
      <div className="lg:ml-[8%] p-[3%] md:p-[2%]">
        <div className="lg:hidden">
          <div className="flex justify-end items-center mb-[1%] mx-[3%] md:mx-[1%]">
            <div className="mr-2">
              <p className="text-xs">Filter date</p>
              <input
                type="date"
                className="text-xs p-[1%] px-[3%] h-[30px] bg-white w-24 lg:w-full rounded-lg outline-none border border-[#444444] cursor-pointer"
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col lg:flex-row justify-between lg:items-center mx-[3%] md:mx-[1%] mb-[2%]">
          <div className="flex items-center justify-between">
            <p className="font-semibold">Coupon</p>

            {/* <button className='lg:hidden flex items-center ml-2 h-[35px] border-[1px] border-[#444444] rounded-lg px-2 text-[#444444] text-[12px] bg-white hover:bg-[#444444] hover:text-white' onClick={handleAddCoupon}> 
                            Add&nbsp;Coupon
                        </button> */}
          </div>

          <div className="flex my-[2%] lg:my-0">
            <div className="mr-2 p-2 px-5 overflow-hidden w-[50px] h-[35px] hover:w-[250px] bg-white shadow-sm rounded-lg flex group items-center hover:duration-300 duration-300 cursor-pointer">
              <div className="flex items-center justify-center fill-[#444444]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="Isolation_Mode"
                  data-name="Isolation Mode"
                  viewBox="0 0 24 24"
                  width="12"
                  height="12"
                >
                  <path d="M18.9,16.776A10.539,10.539,0,1,0,16.776,18.9l5.1,5.1L24,21.88ZM10.5,18A7.5,7.5,0,1,1,18,10.5,7.507,7.507,0,0,1,10.5,18Z"></path>
                </svg>
              </div>
              <input
                type="text"
                className="outline-none text-xs bg-transparent w-full text-gray-500 font-normal px-4"
                placeholder="Search here..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>

            <div className="hidden lg:block ml-2 mr-1 -mt-3.5">
              <p className="text-xs">Filter date</p>
              <input
                type="date"
                className="text-xs p-[1%] px-[3%] h-[30px] bg-white w-24 lg:w-full rounded-lg outline-none border border-[#444444] cursor-pointer"
              />
            </div>

            <button className="download_btn ml-2">
              <svg
                className="svgIcon"
                viewBox="0 0 384 512"
                height="1rem"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"></path>
              </svg>
              <span className="icon2"></span>
              <span className="tooltip">Download</span>
            </button>

            {/* <div className='hidden lg:block'> */}
            <button
              className="flex items-center ml-2 h-[35px] border-[1px] border-[#444444] rounded-lg px-2 text-[#444444] text-[12px] bg-white hover:bg-[#877D6F] hover:text-white"
              onClick={handleAddCoupon}
            >
              Add&nbsp;Coupon
            </button>
            {/* </div> */}
          </div>
        </div>

        <div className="mx-[2%] md:mx-[1%] overflow-x-auto">
          <table className="w-full border-collapse text-[#7C7C7C] text-[14px] 2xl:text-[18px]">
            <thead className="bg-[#E7ECEB]">
              <tr>
                {tableTitles.map((tableTitle, index) => (
                  <th key={index} className="border-b p-2 text-left">
                    {tableTitle}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {currentRows.map((row, index) => (
                <tr
                  key={index}
                  className={`${index % 2 === 0 ? "bg-white" : "bg-[#F7F7F7]"}`}
                >
                  <td className="p-2">{row.id}</td>
                  <td className="p-2">{row.coupon_name}</td>
                  <td className="p-2">{row.discount_percentage}</td>
                  <td className="p-2">{row.expiryDate}</td>
                  <td className="p-2 flex justify-start">
                    <button
                      onClick={() => handleEditCoupon(row)}
                      className="text-[18px] hover:scale-110"
                    >
                      <HiOutlineInformationCircle />
                    </button>
                    <button
                      className="text-[19px] ml-[20%] hover:scale-110 hover:text-red-700"
                      onClick={() => openForm_Delete(row.id)}
                    >
                      <MdOutlineDeleteForever />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Pagination
          rowsPerPage={rowsPerPage}
          totalRows={tableRows.length}
          paginate={paginate}
          currentPage={currentPage}
        />

        {isHandle_Delete && (
          <>
            <div className="overlay_Delete" onClick={closeForm_Delete}></div>
            <div className="popup_form_Delete">
              <span className="close_Delete" onClick={closeForm_Delete}>
                &times;
              </span>
              <div>
                <p className="text-white mb-[5%]">
                  Are you sure you want to delete this Coupon?
                </p>
                <button
                  className="bg-white text-[#877D6F] rounded-lg p-[1%] w-[20%] hover:scale-90 mr-[2%]"
                  onClick={closeForm_Delete}
                >
                  Cancel
                </button>
                <button
                  className="bg-[#877D6F] text-white rounded-lg p-[1%] w-[20%] hover:scale-90"
                  onClick={deleteCoupon}
                >
                  Yes
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default GenerateCoupon;
